import withAuthRedirect from '../../withAuthRedirect';

const Rental = () => {
  return (
    <>
      Rental
    </>
  );
}

export default withAuthRedirect(Rental)
