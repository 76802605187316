// src/components/Partner.js
import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Row, Table, Tag } from 'antd';
import { fetchPartners, fetchPartnersWithTitle } from './api';
import Search from 'antd/es/input/Search';
import { Link } from 'react-router-dom';
import withAuthRedirect from '../../withAuthRedirect';

const Partner = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchData(current, pageSize, searchTerm);
  }, [current, pageSize, searchTerm]);

  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const { data, total } = searchTerm
        ? await fetchPartnersWithTitle(searchTerm, page, pageSize)
        : await fetchPartners(page, pageSize);
      setItems(data);
      setTotal(total);
    } catch (error) {
      // Handle error as needed
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrent(1); // Reset to first page on search
  };

  const handleTableChange = (page, pageSize) => {
    setCurrent(page);
    setPageSize(pageSize);
    fetchData(current, pageSize, searchTerm);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '업체명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={`/partner/${record.id}`}>{text}</Link>,
    },
    {
      title: '제휴 여부',
      dataIndex: 'is_affiliated',
      key: 'is_affiliated',
      render: isAffiliated => (
        <Tag color={isAffiliated ? 'blue' : 'red'}>
          {isAffiliated ? '제휴' : '비제휴'}
        </Tag>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '영업 여부',
      dataIndex: 'is_active',
      key: 'is_active',
      render: isActive => (
        <Tag color={isActive ? 'green' : 'volcano'}>
          {isActive ? '영업중' : '-'}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col flex="1">
          <Search
            placeholder="업체명으로 검색해보세요."
            enterButton
            onSearch={handleSearch}
          />
        </Col>
        <Col>
          <Link to="/partner/new">
            <Button type="primary">업체 생성하기</Button>
          </Link>
        </Col>
      </Row>

      <Divider />

      <Table
        columns={columns}
        dataSource={items}
        loading={loading}
        rowKey="id"
        pagination={{
          current,
          pageSize,
          total,
          showSizeChanger: true,
          onChange: handleTableChange,
        }}
      />
    </>
  );
}

export default withAuthRedirect(Partner)
