import axios from 'axios';

const API_URL = 'http://localhost:9999';

export const fetchStaffs = async () => {
  try {
    const response = await axios.get(`${API_URL}/staffs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching staffs:', error);
    throw error;
  }
};

export const fetchStaffById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/staffs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching staff:', error);
    throw error;
  }
};

export const updateStaff = async (id, staff) => {
  try {
    const response = await axios.put(`${API_URL}/staffs/${id}`, staff);
    return response.data;
  } catch (error) {
    console.error('Error updating staff:', error);
    throw error;
  }
};

export const createStaff = async (staff) => {
  try {
    const response = await axios.post(`${API_URL}/staffs`, staff);
    return response.data;
  } catch (error) {
    console.error('Failed to create staff:', error);
    throw error;
  }
};
