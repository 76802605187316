import './App.css';
import React, { useContext, useState } from 'react';
import Sidebar from './layout/Sidebar';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { Breadcrumb, Button, ConfigProvider, Layout, theme } from 'antd';
import Login from './pages/auth/login';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import AuthContext from './AuthContext';

function App() {
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: {
      colorBgContainer,
      borderRadiusLG
    },
  } = theme.useToken();

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const getBreadcrumbItems = (pathname) => {
    const pathnames = pathname.split('/')
      .filter((item) => item);

    const breadcrumbItems = pathnames.filter((each) => isNaN(each))
      .map((_, index) => {
        const url = `/${pathnames.slice(0, index + 1)
          .join('/')}`;
        switch (url) {
          case '/home':
            return <Breadcrumb.Item key={url}><Link to={url}>Home</Link></Breadcrumb.Item>;
          case '/partner':
            return <Breadcrumb.Item key={url}><Link to={url}>Partners</Link></Breadcrumb.Item>;
          case '/reservation':
            return <Breadcrumb.Item key={url}><Link to={url}>Reservation</Link></Breadcrumb.Item>;
          case '/rental':
            return <Breadcrumb.Item key={url}><Link to={url}>Suit Rental</Link></Breadcrumb.Item>;
          case '/client':
            return <Breadcrumb.Item key={url}><Link to={url}>Clients</Link></Breadcrumb.Item>;
          default:
            return <Breadcrumb.Item key={url}><Link
              to={url}>{pathnames[index]}</Link></Breadcrumb.Item>;
        }
      });

    if (pathnames.length > 1 && !isNaN(pathnames[1])) {
      breadcrumbItems.push(<Breadcrumb.Item key="detail">Detail</Breadcrumb.Item>);
    }

    return [...breadcrumbItems];
  };

  const isLoginRoute = location.pathname === '/auth/login';

  const handleLogout = () => {
    logout();
    navigate('/auth/login');
  };

  return (
    <>
      {isLoginRoute ? (
        <Login/>
      ) : (
        <>
          <ConfigProvider theme={{ hashed: false }}>
            <Layout>
              <Sidebar collapsed={collapsed}/>

              <Layout>
                <Header
                  style={{
                    padding: 0,
                    background: '#f5f5f5',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #e9ebec',
                  }}
                >
                  {/* <div style={{ marginRight: '25px' }}> */}
                  {/*   <LogoutOutlined */}
                  {/*     style={{ fontWeight: 'bold' }} */}
                  {/*     onClick={handleLogout} */}
                  {/*   /> */}
                  {/* </div> */}

                </Header>

                <Content
                  style={{
                    margin: '0 16px',
                  }}
                >
                  <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Aplomb</Breadcrumb.Item>
                    {getBreadcrumbItems(location.pathname)}
                  </Breadcrumb>

                  <div
                    style={{
                      padding: 24,
                      minHeight: '86vh',
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                    }}
                  >
                    <Outlet/>
                  </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                  ©{new Date().getFullYear()} Created by DJ
                </Footer>
              </Layout>
            </Layout>
          </ConfigProvider>
        </>
      )}
    </>
  );
}

export default App;
