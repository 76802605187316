import React from 'react';
import { Form, Input, Button, Radio, message, Space } from 'antd';
import { createPartner } from './api';
import { useNavigate } from 'react-router-dom';
import withAuthRedirect from '../../withAuthRedirect';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const PartnerCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      await createPartner(values);
      message.success('Partner 생성이 성공적으로 완료되었습니다.');
      navigate('/partner'); // Redirect to partners list
    } catch (error) {
      message.error('Partner 생성하는데 오류가 발생했습니다.');
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      name="partnerCreate"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label="업체명"
        name="title"
        rules={[{ required: true, message: '업체명을 입력해주세요!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="제휴 여부"
        name="is_affiliated"
        rules={[{ required: true, message: '제휴 여부를 선택해주세요!' }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>제휴</Radio.Button>
          <Radio.Button value={false}>비제휴</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="연락처"
        name="phone"
        rules={[{ required: true, message: '연락처를 입력해주세요!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="영업 여부"
        name="is_active"
        rules={[{ required: true, message: '영업 여부를 선택해주세요!' }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>제휴</Radio.Button>
          <Radio.Button value={false}>비제휴</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...tailLayout} style={{ marginTop: 45 }}>
        <Space>
          <Button htmlType="button" onClick={() => { navigate('/partner')}}>
            돌아가기
          </Button>
          <Button type="primary" htmlType="submit">
            생성하기
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default withAuthRedirect(PartnerCreate);
