import axios from 'axios';

const API_URL = 'http://localhost:9999';

export const fetchRoles = async () => {
  try {
    const response = await axios.get(`${API_URL}/roles`);
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

export const fetchRoleById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/roles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching role:', error);
    throw error;
  }
};
