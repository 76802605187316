import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, message, Radio, Row, Select, Spin } from 'antd';
import { createStaff } from '../../../services/staff';
import { fetchRoles } from '../../../services/role';

const StaffCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesData = await fetchRoles();
        setRoles(rolesData);
      } catch (error) {
        message.error('Failed to load roles');
      }
    };

    getRoles();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await createStaff({ ...values, role_id: parseInt(values.role_id) });
      message.success('Staff created successfully');
      navigate('/admin/staff');
    } catch (error) {
      message.error('Failed to create staff');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="Create Staff" bordered={false} style={{ margin: 20 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ is_active: true }}  // Set default value for is_active
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="role_id" label="Role" rules={[{ required: true, message: 'Please select a role!' }]}>
              <Select>
                {roles.map((role) => (
                  <Select.Option key={role.id} value={role.id.toString()}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input the phone number!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="login_id" label="Login ID" rules={[{ required: true, message: 'Please input the login ID!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="login_password" label="Login Password" rules={[{ required: true, message: 'Please input the login password!' }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Active"
              name="is_active"
              rules={[{ required: true, message: 'Please select active status!' }]}
            >
              <Radio.Group>
                <Radio.Button value={true}>Active</Radio.Button>
                <Radio.Button value={false}>Inactive</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default StaffCreate;
