import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import routers from './routes/routes';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={routers}/>
      </AuthProvider>
    </React.StrictMode>
);
