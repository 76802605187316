import axios from 'axios';

const API_BASE_URL = 'http://localhost:9999';

export const login = async (username, password) => {
  // Mock API call
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (username === 'admin' && password === 'password') {
        resolve({ token: '1234567890abcdef', user: { username: 'admin' } });
      } else {
        reject(new Error('Invalid credentials'));
      }
    }, 1000);
  });
};
