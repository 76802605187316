import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchClientById, updateClient, deleteClient } from '../../services/client';
import { fetchStaffs } from '../../services/staff';
import { fetchPlanners } from '../../services/planner';
import { Form, Input, Card, Spin, message, Row, Col, Button, Select, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ClientDetail = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [planners, setPlanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { confirm } = Modal;

  useEffect(() => {
    const getData = async () => {
      try {
        const [clientData, staffsData, plannersData] = await Promise.all([
          fetchClientById(id),
          fetchStaffs(),
          fetchPlanners()
        ]);

        setClient(clientData);
        setStaffs(staffsData);
        setPlanners(plannersData);

        const staff = staffsData.find(s => s.id === clientData.staff_id);
        const planner = plannersData.find(p => p.id === clientData.planner_id);

        form.setFieldsValue({
          ...clientData,
          staff_id: staff ? staff.id : null,
          planner_id: planner ? planner.id : null,
        });
      } catch (error) {
        message.error('고객 정보를 불러오는 데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [id, form]);

  const handleEditSubmit = async (values) => {
    try {
      await updateClient(id, values);
      message.success('고객 정보가 성공적으로 수정되었습니다.');
      setClient({ ...client, ...values });
    } catch (error) {
      message.error('고객 정보를 수정하는 데 실패했습니다.');
    }
  };

  const handleDelete = () => {
    confirm({
      title: '고객을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '이 작업은 되돌릴 수 없습니다.',
      onOk: async () => {
        try {
          await deleteClient(id);
          message.success('고객이 성공적으로 삭제되었습니다.');
          navigate('/client');
        } catch (error) {
          message.error('고객을 삭제하는 데 실패했습니다.');
        }
      },
    });
  };

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '20% auto' }} />;
  }

  if (!client) {
    return <p>고객을 찾을 수 없습니다.</p>;
  }

  return (
    <Card title="고객 상세 정보" bordered={false} style={{ margin: 0 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditSubmit}
      >
        <Form.Item label="고객 ID" name="id" hidden>
          <Input value={client.id} readOnly />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="이름" name="name" rules={[{ required: true, message: '이름을 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="플래너" name="planner_id" rules={[{ required: true, message: '플래너를 선택하세요' }]}>
              <Select>
                {planners.map((planner) => (
                  <Select.Option key={planner.id} value={planner.id}>
                    {planner.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="신부 이름" name="bride_name" rules={[{ required: true, message: '신부 이름을 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="담당 직원" name="staff_id" rules={[{ required: true, message: '담당 직원을 선택하세요' }]}>
              <Select>
                {staffs.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="전화번호" name="phone" rules={[{ required: true, message: '전화번호를 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button type="default" onClick={() => navigate('/clients')} style={{ marginRight: '8px' }}>
            돌아가기
          </Button>
          <Button danger onClick={handleDelete} style={{ marginRight: '8px' }}>
            삭제
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
            수정
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClientDetail;
