import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Input, message, Radio, Row, Select, Spin } from 'antd';
import { fetchStaffById, updateStaff } from '../../../services/staff';
import { fetchRoleById, fetchRoles } from '../../../services/role';

const StaffDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesData = await fetchRoles();
        setRoles(rolesData);
      } catch (error) {
        message.error('Failed to load roles');
      }
    };

    const getStaffAndRole = async () => {
      try {
        const staffData = await fetchStaffById(id);
        setStaff(staffData);

        const roleData = await fetchRoleById(staffData.role_id);
        setRoleName(roleData.name);

        form.setFieldsValue({
          ...staffData,
          role_id: staffData.role_id.toString(),
        });
      } catch (error) {
        message.error('Failed to load staff details');
      } finally {
        setLoading(false);
      }
    };

    Promise.all([getStaffAndRole(), getRoles()]);
  }, [id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateStaff(id, { ...values, role_id: parseInt(values.role_id) });
      message.success('Staff details updated successfully');
    } catch (error) {
      message.error('Failed to update staff details');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '20% auto' }} />;
  }

  if (!staff) {
    return <p>Staff not found</p>;
  }

  return (
    <Card title="직원 상세 정보" bordered={false} style={{ margin: 0 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={staff}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="role_id" label="Role" rules={[{ required: true, message: 'Please select a role!' }]}>
              <Select>
                {roles.map((role) => (
                  <Select.Option key={role.id} value={role.id.toString()}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input the phone number!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="created" label="입사일">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="login_id" label="Login ID" rules={[{ required: true, message: 'Please input the login ID!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="login_password" label="Login Password" rules={[{ required: true, message: 'Please input the login password!' }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="재직 여부"
              name="is_active"
              rules={[{
                required: true,
                message: '재직 여부를 선택해주세요!'
              }]}
            >
              <Radio.Group>
                <Radio.Button value={true}>재직중</Radio.Button>
                <Radio.Button value={false}>퇴사</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            수정
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default StaffDetail;
