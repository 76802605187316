import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import { createClient } from '../../services/client';
import { fetchStaffs } from '../../services/staff';
import { fetchPlanners } from '../../services/planner';

const ClientCreate = () => {
  const [staffs, setStaffs] = useState([]);
  const [planners, setPlanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [staffsData, plannersData] = await Promise.all([
          fetchStaffs(),
          fetchPlanners(),
        ]);

        setStaffs(staffsData);
        setPlanners(plannersData);
      } catch (error) {
        message.error('플래너 및 직원 정보를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await createClient(values);
      message.success('고객이 성공적으로 생성되었습니다.');
      navigate('/client');
    } catch (error) {
      message.error('고객을 생성하는 데 실패했습니다.');
    }
  };

  return (
    <Card title="고객 상세 정보" bordered={false} style={{ margin: 0 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleCreate}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="이름" name="name" rules={[{ required: true, message: '이름을 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="플래너" name="planner_id" rules={[{ required: true, message: '플래너를 선택하세요' }]}>
              <Select>
                {planners.map((planner) => (
                  <Select.Option key={planner.id} value={planner.id}>
                    {planner.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="전화번호" name="phone" rules={[{ required: true, message: '전화번호를 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="담당 직원" name="staff_id" rules={[{ required: true, message: '담당 직원을 선택하세요' }]}>
              <Select>
                {staffs.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="신부 이름" name="bride_name" rules={[{ required: true, message: '신부 이름을 입력하세요' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right' }}>
          <Button type="default" onClick={() => navigate('/client')} style={{ marginRight: '8px' }}>
            돌아가기
          </Button>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClientCreate;
