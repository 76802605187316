import React, { useEffect, useState } from 'react';
import { Table, message, Spin, Input, Row, Col, Button, Select, Divider, Card, Modal } from 'antd';
import { fetchPlanners } from '../../services/planner';
import { fetchStaffs } from '../../services/staff';
import { createMapFromArray } from '../../services/utils';
import { fetchClients, deleteClient } from '../../services/client';
import { Link } from 'react-router-dom';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { Search } = Input;
const { confirm } = Modal;

const Client = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [planners, setPlanners] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [nameQuery, setNameQuery] = useState('');
  const [brideNameQuery, setBrideNameQuery] = useState('');
  const [staffIdQuery, setStaffIdQuery] = useState('');
  const [plannerIdQuery, setPlannerIdQuery] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [plannersData, staffsData] = await Promise.all([
          fetchPlanners(),
          fetchStaffs(),
        ]);

        setPlanners(plannersData);
        setStaffs(staffsData);
      } catch (error) {
        message.error('플래너 및 직원 정보를 불러오는데 실패했습니다.');
      }
    };

    fetchInitialData();
  }, []);

  const getData = async (page, pageSize, nameQuery = '', brideNameQuery = '', staffIdQuery = '', plannerIdQuery = '') => {
    setLoading(true);
    try {
      const clientResponse = await fetchClients(page, pageSize, nameQuery, brideNameQuery, staffIdQuery, plannerIdQuery);

      const {
        data: clients,
        total
      } = clientResponse;

      const plannerMap = createMapFromArray(planners, 'id', 'name');
      const staffMap = createMapFromArray(staffs, 'id', 'name');

      const clientWithPlannerName = clients.map((each) => ({
        ...each,
        plannerName: plannerMap[each.planner_id],
        staffName: staffMap[each.staff_id],
      }));

      setClients(clientWithPlannerName);
      setPagination((prev) => ({
        ...prev,
        total,
      }));
    } catch (error) {
      message.error('고객 정보를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (planners.length > 0 && staffs.length > 0) {
      getData(pagination.current, pagination.pageSize, nameQuery, brideNameQuery, staffIdQuery, plannerIdQuery);
    }
  }, [pagination.current, pagination.pageSize, planners, staffs, nameQuery, brideNameQuery, staffIdQuery, plannerIdQuery]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleDelete = (id) => {
    confirm({
      title: '고객을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '이 작업은 되돌릴 수 없습니다.',
      onOk: async () => {
        try {
          await deleteClient(id);
          message.success('고객이 성공적으로 삭제되었습니다.');
          getData(pagination.current, pagination.pageSize, nameQuery, brideNameQuery, staffIdQuery, plannerIdQuery);
        } catch (error) {
          message.error('고객을 삭제하는 데 실패했습니다.');
        }
      },
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`/client/${record.id}`}>{text}</Link>,
    },
    {
      title: '신부 이름',
      dataIndex: 'bride_name',
      key: 'bride_name',
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '플래너',
      dataIndex: 'plannerName',
      key: 'planner_name',
    },
    {
      title: '담당 직원',
      dataIndex: 'staffName',
      key: 'staff_name',
    },
    {
      title: '삭제',
      key: 'action',
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDelete(record.id)}>
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card title="고객 검색" bordered={false} style={{ margin: 0 }}>
        <Row gutter={16} style={{ marginBottom: 9 }}>
          <Col span={7}>
            <Search
              placeholder="이름으로 검색"
              value={nameQuery}
              onChange={(e) => setNameQuery(e.target.value)}
              allowClear
            />
          </Col>
          <Col span={7}>
            <Search
              placeholder="신부 이름으로 검색"
              value={brideNameQuery}
              onChange={(e) => setBrideNameQuery(e.target.value)}
              allowClear
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={7}>
            <Select
              placeholder="담당 직원으로 검색"
              value={staffIdQuery || undefined}
              onChange={(value) => setStaffIdQuery(value)}
              allowClear
              style={{ width: '100%' }}
            >
              {staffs.map((staff) => (
                <Select.Option key={staff.id} value={staff.id}>
                  {staff.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <Select
              placeholder="담당 플래너로 검색"
              value={plannerIdQuery || undefined}
              onChange={(value) => setPlannerIdQuery(value)}
              allowClear
              style={{ width: '100%' }}
            >
              {planners.map((planner) => (
                <Select.Option key={planner.id} value={planner.id}>
                  {planner.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Card>

      <Divider/>

      <Table
        columns={columns}
        dataSource={clients}
        rowKey="id"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Client;
