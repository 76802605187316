import React, { useContext, useEffect } from 'react';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { login as loginService } from './api'; // Import the login function
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../AuthContext'; // Import the CSS file

const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Use the login function from context

  // useEffect(() => {
  //   if (auth.isAuthenticated) navigate('/home')
  // }, [auth.isAuthenticated, navigate]);

  const onFinish = async (values) => {
    try {
      const { username, password } = values;
      const response = await loginService(username, password);
      console.log('Login successful:', response);
      // Use the login function from context to update the state
      login(response.user, response.token);
      message.success('Login successful!');
      navigate('/home');
    } catch (error) {
      message.error('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Title level={3} className="login-title">Aplomb</Title>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
              Log in
            </Button>
          </Form.Item>
        </Form>
        <p className="login-footer">
          계정이 없다면 관리자에게 문의해주세요!
        </p>
      </Card>
    </div>
  );
};

export default Login;
