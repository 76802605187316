import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card, Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  Tag
} from 'antd';
import {
  createPlanner,
  deletePartner, deletePlanner,
  fetchPartner,
  fetchPlannersByPartnerId,
  updatePartner, updatePlanner
} from './api';
import { DeleteOutlined } from '@ant-design/icons';
import withAuthRedirect from '../../withAuthRedirect';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const PartnerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [partner, setPartner] = useState(null);
  const [planners, setPlanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPlanner, setCurrentPlanner] = useState(null);

  const [form] = Form.useForm();
  const [plannerForm] = Form.useForm();

  useEffect(() => {
    const getPartner = async () => {
      try {
        const data = await fetchPartner(id);
        setPartner(data);
        form.setFieldsValue(data); // Set form values once data is fetched

        const plannersData = await fetchPlannersByPartnerId(id);
        setPlanners(plannersData);
      } catch (error) {
        console.error('Partner 정보를 가져오는데 오류가 발생했습니다.', error);
      } finally {
        setLoading(false);
      }
    };

    getPartner();
  }, [id, form]);

  const onFinish = async (values) => {
    try {
      await updatePartner(id, values);
      message.success('Partner 수정이 성공적으로 완료되었습니다.');
      navigate('/partner');
    } catch (error) {
      message.error('Partner 수정에 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    try {
      await deletePartner(id);
      message.success('Partner 성공적으로 삭제했습니다.');
      navigate('/partner');
    } catch (error) {
      message.error('Partner 삭제하는데 오류가 발생했습니다.');
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `${partner.title}(${partner.is_affiliated ? '제휴' : '비제휴'}) 업체를 삭제하시겠습니까?`,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk: handleDelete,
    });
  };

  const handleAddPlanner = async (values) => {
    try {
      values.partner_id = id;  // Add partner_id to the form values
      await createPlanner(values);
      const plannersData = await fetchPlannersByPartnerId(id);
      setPlanners(plannersData);
      message.success('Planner 추가가 성공적으로 완료되었습니다.');
      setIsModalVisible(false);
      plannerForm.resetFields();  // Reset the form fields
    } catch (error) {
      message.error('Planner 추가에 오류가 발생했습니다.');
    }
  };

  const handleEditPlanner = async (values) => {
    try {
      await updatePlanner(currentPlanner.id, values);
      const plannersData = await fetchPlannersByPartnerId(id);
      setPlanners(plannersData);
      message.success('Planner 수정이 성공적으로 완료되었습니다.');
      setIsModalVisible(false);
      setCurrentPlanner(null);
      setIsEditing(false);
      plannerForm.resetFields();  // Reset the form fields
    } catch (error) {
      message.error('Planner 수정에 오류가 발생했습니다.');
    }
  };

  const handleDeletePlanner = async (plannerId) => {
    try {
      await deletePlanner(plannerId);
      const plannersData = await fetchPlannersByPartnerId(id);
      setPlanners(plannersData);
      message.success('Planner 성공적으로 삭제되었습니다.');
    } catch (error) {
      message.error('Planner 삭제하는데 오류가 발생했습니다.');
    }
  };

  const showDeletePlannerConfirm = (plannerId) => {
    Modal.confirm({
      title: '이 플래너를 삭제하시겠습니까?',
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk: () => handleDeletePlanner(plannerId),
    });
  };

  const showEditModal = (planner) => {
    setCurrentPlanner(planner);
    setIsEditing(true);
    plannerForm.setFieldsValue(planner);
    setIsModalVisible(true);
  };

  const plannerColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Button type="link" onClick={() => showEditModal(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '재직 여부',
      dataIndex: 'is_active',
      key: 'is_active',
      render: isActive => (
        <Tag color={isActive ? 'green' : 'volcano'}>
          {isActive ? '재직중' : '-'}
        </Tag>
      ),
    },
    {
      title: '삭제',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showDeletePlannerConfirm(record.id)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" style={{
      display: 'block',
      margin: '20% auto'
    }}/>;
  }

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="partnerDetail"
        initialValues={partner}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="업체명"
          name="title"
          rules={[{
            required: true,
            message: '업체명을 입력해주세요!'
          }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="제휴 여부"
          name="is_affiliated"
          rules={[{
            required: true,
            message: '제휴 여부를 선택해주세요!'
          }]}
        >
          <Radio.Group>
            <Radio.Button value={true}>제휴</Radio.Button>
            <Radio.Button value={false}>비제휴</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="연락처"
          name="phone"
          rules={[{
            required: true,
            message: '연락처를 입력해주세요!'
          }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="영업 여부"
          name="is_active"
          rules={[{
            required: true,
            message: '영업 여부를 선택해주세요!'
          }]}
        >
          <Radio.Group>
            <Radio.Button value={true}>영업</Radio.Button>
            <Radio.Button value={false}>폐업</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{
          marginBottom: 70
        }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button htmlType="button" onClick={() => {
                  navigate('/partner');
                }}>
                  돌아가기
                </Button>
                <Button type="primary" htmlType="submit">
                  수정하기
                </Button>
                <Button danger onClick={showDeleteConfirm}>
                  삭제하기
                </Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Card
        title="플래너 관리"
        extra={
          <Button onClick={() => setIsModalVisible(true)}>
            플래너 추가
          </Button>
        }
      >
        <Table
          columns={plannerColumns}
          dataSource={planners}
          rowKey="id"
          pagination={false}
        />
      </Card>

      <Modal
        title={isEditing ? "Edit Planner" : "Add Planner"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          plannerForm.resetFields();  // Reset the form fields
          setCurrentPlanner(null);
          setIsEditing(false);
        }}
        footer={null}
      >
        <Form
          form={plannerForm}
          layout="vertical"
          initialValues={{ is_active: true }} // Set default value for is_active
          onFinish={isEditing ? handleEditPlanner : handleAddPlanner}
        >
          <Form.Item
            label="이름"
            name="name"
            rules={[{ required: true, message: '이름을 입력해주세요!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="연락처"
            name="phone"
            rules={[{ required: true, message: '연락처를 입력해주세요!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="partner_id" initialValue={id} hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="재직 여부"
            name="is_active"
            rules={[{
              required: true,
              message: '재직 여부를 선택해주세요!'
            }]}
          >
            <Radio.Group>
              <Radio.Button value={true}>재직중</Radio.Button>
              <Radio.Button value={false}>퇴사</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button danger htmlType="button" onClick={() => setIsModalVisible(false)}>
                    취소
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {isEditing ? "수정" : "추가"}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withAuthRedirect(PartnerDetail);
