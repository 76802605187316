import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import ErrorPage from '../error';
import Home from '../pages/home';
import Login from '../pages/auth/login';
import Reservation from '../pages/reservation';
import Rental from '../pages/rental';
import Partner from '../pages/partner';
import PartnerDetail from '../pages/partner/detail';
import PartnerCreate from '../pages/partner/create';
import Client from '../pages/client';
import React from 'react';
import Staff from '../pages/admin/staff';
import Role from '../pages/admin/role';
import StaffDetail from '../pages/admin/staff/detail';
import StaffCreate from '../pages/admin/staff/create';
import ClientCreate from '../pages/client/create';
import ClientDetail from '../pages/client/detail';

const routers = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "auth/login",
        element: <Login />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "reservation",
        element: <Reservation />,
      },
      {
        path: "rental",
        element: <Rental />,
      },
      {
        path: "partner",
        element: <Partner />,
      },
      {
        path: "partner/:id",
        element: <PartnerDetail />,
      },
      {
        path: "partner/new",
        element: <PartnerCreate />,
      },
      {
        path: "client",
        element: <Client />,
      },
      {
        path: "client/new",
        element: <ClientCreate />,
      },
      {
        path: "client/:id",
        element: <ClientDetail />,
      },
      {
        path: "admin/staff",
        element: <Staff />,
      },
      {
        path: "admin/staff/new",
        element: <StaffCreate />,
      },
      {
        path: "admin/staff/:id",
        element: <StaffDetail />,
      },
      {
        path: "admin/role",
        element: <Role />,
      },
    ]
  }
]);

export default routers
