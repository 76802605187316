import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const withAuthRedirect = (WrappedComponent) => {
  return (props) => {
    const { auth } = useContext(AuthContext); // Use the AuthContext to get auth state
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const pathIncludesAuth = location.pathname.includes('auth');
      if (pathIncludesAuth && auth.isAuthenticated) {
        navigate('/home');
      }

      if (!auth.isAuthenticated) {
        navigate('/auth/login')
      }
    }, [auth.isAuthenticated, location.pathname, navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuthRedirect;
