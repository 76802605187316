// src/api/api.js
import axios from 'axios';

const API_BASE_URL = 'http://localhost:9999';

export const fetchPartner = (id) => {
  return axios.get(`${API_BASE_URL}/partners/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the partner details!', error);
      throw error;
    });
};

export const updatePartner = (id, partner) => {
  return axios.put(`${API_BASE_URL}/partners/${id}`, partner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error updating the partner details!', error);
      throw error;
    });
};

export const deletePartner = (id) => {
  return axios.delete(`${API_BASE_URL}/partners/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error deleting the partner!', error);
      throw error;
    });
};

export const createPartner = (partner) => {
  return axios.post(`${API_BASE_URL}/partners`, partner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error creating the partner!', error);
      throw error;
    });
};

export const fetchPartners = (page = 1, limit = 10, query = '') => {
  const url = `${API_BASE_URL}/partners?_page=${page}&_limit=${limit}${query}`;
  return axios.get(url)
    .then(response => ({
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10)
    }))
    .catch(error => {
      console.error('There was an error fetching the data!', error);
      throw error;
    });
};

export const fetchPartnersWithTitle = (titleSubstring, page = 1, limit = 10) => {
  const query = `&title_like=${titleSubstring}`;
  return fetchPartners(page, limit, query);
};

export const fetchPlannersByPartnerId = (partnerId) => {
  return axios.get(`${API_BASE_URL}/planners?partner_id=${partnerId}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the planners!', error);
      throw error;
    });
};

export const createPlanner = (planner) => {
  return axios.post(`${API_BASE_URL}/planners`, planner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error creating the planner!', error);
      throw error;
    });
};

export const updatePlanner = (id, planner) => {
  return axios.put(`${API_BASE_URL}/planners/${id}`, planner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error updating the planner!', error);
      throw error;
    });
};

export const deletePlanner = (id) => {
  return axios.delete(`${API_BASE_URL}/planners/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error deleting the planner!', error);
      throw error;
    });
};
