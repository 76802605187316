import React, { useEffect, useState } from 'react';
import { Button, Col, message, Row, Table, Tag } from 'antd';
import withAuthRedirect from '../../../withAuthRedirect';
import { Link } from 'react-router-dom';
import { fetchStaffs } from '../../../services/staff';
import { fetchRoles } from '../../../services/role';
import Search from 'antd/es/input/Search';

const Staff = () => {
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getStaffsAndRoles = async () => {
      setLoading(true);
      try {
        const [staffsData, rolesData] = await Promise.all([fetchStaffs(), fetchRoles()]);
        const rolesMap = rolesData.reduce((map, role) => {
          map[role.id] = role.name;
          return map;
        }, {});

        const staffsWithRoleNames = staffsData.map((staff) => ({
          ...staff,
          role_name: rolesMap[staff.role_id],
        }));

        setStaffs(staffsWithRoleNames);
      } catch (error) {
        message.error('Failed to load staffs and roles');
      } finally {
        setLoading(false);
      }
    };

    getStaffsAndRoles();
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`/admin/staff/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <Link to="/admin/staff/new">
            <Button type="primary">직원 추가하기</Button>
          </Link>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={staffs}
        loading={loading}
        rowKey="id"
      />
    </>
  );
};

export default withAuthRedirect(Staff);
