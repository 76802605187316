import React, { useContext } from 'react';
import {
  ApartmentOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Menu, Button, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../AuthContext';

const { Sider } = Layout;

function getItem({ key, icon, label, children }) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem({
    key: '/home',
    icon: <HomeOutlined />,
    label: <Link to="/home">Home</Link>,
  }),
  getItem({
    key: '/reservation',
    icon: <CalendarOutlined />,
    label: <Link to="/reservation">Reservation</Link>,
  }),
  getItem({
    key: '/rental',
    icon: <CheckSquareOutlined />,
    label: <Link to="/rental">Suit Rental</Link>,
  }),
  getItem({
    key: '/partner',
    icon: <HomeOutlined />,
    label: <Link to="/partner">Partners</Link>,
  }),
  getItem({
    key: '/client',
    icon: <UserOutlined />,
    label: <Link to="/client">Clients</Link>,
  }),
  getItem({
    key: '/admin',
    icon: <UserSwitchOutlined />,
    label: "Admin",
    children: [
      getItem({
        key: '/admin/staff',
        icon: <ApartmentOutlined />,
        label: <Link to="/admin/staff">Staff</Link>,
      }),
      getItem({
        key: '/admin/role',
        icon: <UserOutlined />,
        label: <Link to="/admin/role">Role</Link>,
      }),
    ]
  }),
  getItem({
    key: '/auth/logout',
    icon: <UserOutlined />,
    label: <Link to="/auth/logout">Logout</Link>,
  })
];

const Sidebar = ({ collapsed }) => {
  const location = useLocation();
  const { auth, logout } = useContext(AuthContext);
  const getSelectedKey = (pathname) => {
    const rootPath = pathname.split('/')[1];
    return [`/${rootPath}`];
  };
  const selectedKey = getSelectedKey(location.pathname);

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        // console.log(collapsed, type);
      }}>

      <div className="demo-logo-vertical" />

      <Menu
        theme="dark"
        mode="inline"
        inlineCollapsed={false}
        selectedKeys={selectedKey}
        items={items} />
    </Sider>
  );
};

export default Sidebar;
