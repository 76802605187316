import { useNavigate, useRouteError } from 'react-router-dom';
import { Button, Result } from 'antd';

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  const navigate = useNavigate();

  return (
    <div className="error-container">
      <Result
        status="404"
        title="404"
        subTitle="요청이 잘못됐습니다. 개발자에게 문의해주세요."
        extra={<Button type="primary" onClick={() => navigate('/home')}>Back Home</Button>}
      />
    </div>
  );
}

export default ErrorPage;
