import axios from 'axios';

const API_BASE_URL = 'http://localhost:9999';

export const fetchClients = async (page = 1, limit = 10, nameQuery = '', brideNameQuery = '', staffIdQuery = '', plannerIdQuery = '') => {
  let url = `${API_BASE_URL}/clients?_page=${page}&_limit=${limit}`;
  if (nameQuery) {
    url += `&name_like=${nameQuery}`;
  }
  if (brideNameQuery) {
    url += `&bride_name_like=${brideNameQuery}`;
  }
  if (staffIdQuery) {
    url += `&staff_id=${staffIdQuery}`;
  }
  if (plannerIdQuery) {
    url += `&planner_id=${plannerIdQuery}`;
  }
  return axios.get(url)
    .then(response => ({
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    }))
    .catch(error => {
      console.error('고객 정보를 불러오는데 실패했습니다:', error);
      throw error;
    });
};

export const fetchClientById = (id) => {
  const url = `${API_BASE_URL}/clients/${id}`;
  return axios.get(url)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the client details!', error);
      throw error;
    });
};

export const updateClient = (id, data) => {
  const url = `${API_BASE_URL}/clients/${id}`;
  return axios.put(url, data)
    .then(response => response.data)
    .catch(error => {
      console.error('고객 정보를 업데이트하는 중 오류가 발생했습니다!', error);
      throw error;
    });
};

export const createClient = (data) => {
  return axios.post(`${API_BASE_URL}/clients`, data)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error creating the client!', error);
      throw error;
    });
};

export const deleteClient = (id) => {
  return axios.delete(`${API_BASE_URL}/clients/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error deleting the client!', error);
      throw error;
    });
};
