import React, { useEffect, useState } from 'react';
import { Table, message, Button, Modal, Form, Input, Space } from 'antd';
import withAuthRedirect from '../../../withAuthRedirect';
import { fetchRoles, createRole, updateRole, deleteRole } from './api';
import { DeleteOutlined } from '@ant-design/icons';

const Role = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const getRoles = async () => {
      setLoading(true);
      try {
        const data = await fetchRoles();
        setRoles(data);
      } catch (error) {
        message.error('Failed to load roles');
      } finally {
        setLoading(false);
      }
    };

    getRoles();
  }, []);

  const handleCreateRole = async (values) => {
    try {
      await createRole(values);
      message.success('Role created successfully');
      form.resetFields();
      setIsModalVisible(false);
      const data = await fetchRoles();
      setRoles(data);
    } catch (error) {
      message.error('Failed to create role');
    }
  };

  const handleEditRole = async (values) => {
    try {
      await updateRole(currentRole.id, values);
      message.success('Role updated successfully');
      form.resetFields();
      setIsModalVisible(false);
      const data = await fetchRoles();
      setRoles(data);
    } catch (error) {
      message.error('Failed to update role');
    }
  };

  const handleDeleteRole = async (id) => {
    try {
      await deleteRole(id);
      message.success('Role deleted successfully');
      const data = await fetchRoles();
      setRoles(data);
    } catch (error) {
      message.error('Failed to delete role');
    }
  };

  const showEditModal = (role) => {
    setCurrentRole(role);
    setIsEditing(true);
    form.setFieldsValue(role);
    setIsModalVisible(true);
  };

  const showDeleteConfirm = (role) => {
    Modal.confirm({
      title: `${role.name} 권한을 정말 삭제하시겠습니까?`,
      content: ``,
      okText: '네',
      okType: 'danger',
      cancelText: '아니요',
      onOk: () => handleDeleteRole(role.id),
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '권한',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Button type='link' onClick={() => showEditModal(record)}>{text}</Button>
      ),
    },
    {
      title: '삭제',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <span onClick={() => showDeleteConfirm(record)} style={{ color: 'red', cursor: 'pointer' }}>
            <DeleteOutlined />
          </span>
        </Space>
      ),
    }
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button type="primary" onClick={() => {
          setIsEditing(false);
          form.resetFields();
          setIsModalVisible(true);
        }}>
          권한 생성
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={roles}
        loading={loading}
        rowKey="id"
      />
      <Modal
        title={isEditing ? "권한 수정" : "권한 생성"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={isEditing ? handleEditRole : handleCreateRole}>
          <Form.Item
            name="name"
            label="권한"
            rules={[{ required: true, message: 'Please input the role name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit">
                {isEditing ? "수정" : "생성"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withAuthRedirect(Role);