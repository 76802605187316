import axios from 'axios';

const API_BASE_URL = 'http://localhost:9999';

export const fetchPlannersByPartnerId = (partnerId) => {
  return axios.get(`${API_BASE_URL}/planners?partner_id=${partnerId}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the planners!', error);
      throw error;
    });
};

export const fetchPlanners = () => {
  return axios.get(`${API_BASE_URL}/planners`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error getting the planners!', error);
      throw error;
    });
}

export const fetchPlannerById = (id) => {
  const url = `${API_BASE_URL}/planners/${id}`;
  return axios.get(url)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the planner details!', error);
      throw error;
    });
};

export const createPlanner = (planner) => {
  return axios.post(`${API_BASE_URL}/planners`, planner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error creating the planner!', error);
      throw error;
    });
};

export const updatePlanner = (id, planner) => {
  return axios.put(`${API_BASE_URL}/planners/${id}`, planner)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error updating the planner!', error);
      throw error;
    });
};

export const deletePlanner = (id) => {
  return axios.delete(`${API_BASE_URL}/planners/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error deleting the planner!', error);
      throw error;
    });
};
