import axios from 'axios';

const API_BASE_URL = 'http://localhost:9999';

export const fetchReservations = () => {
  return axios.get(`${API_BASE_URL}/reservations`)
    .then(response => response.data)
    .catch(error => {
      console.error('There was an error fetching the reservations!', error);
      throw error;
    });
};
