import withAuthRedirect from '../../withAuthRedirect';

const Home = () => {
  return (
    <>
      Home
    </>
  );
}

export default withAuthRedirect(Home)
