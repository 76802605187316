import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Spin, message, Button, Row, Col, Card } from 'antd';
import { fetchReservations } from '../../services/reservation';
import './index.css';

const Reservation = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getReservations = async () => {
      try {
        const data = await fetchReservations();
        setReservations(data);
      } catch (error) {
        message.error('Failed to fetch reservations');
      } finally {
        setLoading(false);
      }
    };

    getReservations();
  }, []);

  const events = reservations.map(reservation => ({
    title: reservation.title,
    start: reservation.date,
  }));

  const handleDateClick = (arg) => {
    alert(arg.dateStr)
  }

  const eventAdd = (arg) => {
    console.log(arg)
  }

  const eventChange = (arg) => {
    console.log(arg)
  }

  const eventRemove = (arg) => {
    console.log(arg)
  }

  const eventSelect = (arg) => {
    console.log(arg)
  }

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '20% auto' }} />;
  }

  return (
    <>
      <Card>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="threeDay" // timeGridWeek
          allDayText='종일일정'
          events={events}
          eventAdd={eventAdd}
          eventChange={eventChange}
          eventRemove={eventRemove}
          select={eventSelect}
          views={{
            threeDay: {
              type: 'timeGrid',
              duration: { days: 3 },
              buttonText: '3일'
            }
          }}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,threeDay',
          }}
          slotMinTime='08:00'
          slotMaxTime='21:00'
          navLinks="true"
          selectable="true"
          selectMirror="true"
          dayMaxEvents="true"
          editable="true"
          nowIndicator="true"
          expandRows="true"
          locale='ko'
          buttonText={{
            today: '오늘',
            month: '한달',
            week: '일주일',
            day: '하루'
          }}
          height="auto"
          // dateClick={handleDateClick}
        />
      </Card>
    </>
  );
};

export default Reservation;
